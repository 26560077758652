<template>
  <div>
    <a-card :title="$t('入库通知单')">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />{{ $t("返回") }}</a-button>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-row>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="number" :label="$t('入库通知单号')">
                <a-input v-model="form.number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="warehouse" :label="$t('仓库')">
                <a-select v-model="form.warehouse" style="width: 100%">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="area" :label="$t('库区')">
                <AreaSelect v-model="form.area" :warehouse="form.warehouse" :name="form.area_name" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="client" :label="$t('货主')">
                <client-select v-model="form.client"></client-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="counterparty" :label="$t('往来单位')">
                <CounterpartySelect v-model="form.counterparty"></CounterpartySelect>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="order_type" :label="$t('订单类型')">
                <a-select v-model="form.order_type" style="width: 100%">
                  <a-select-option v-for="item in orderTypeItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="external_number" :label="$t('往来单位单号')">
                <a-input v-model="form.external_number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="arrival_date" :label="$t('预计到货日')">
                <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="receipt_files" :label="$t('附件')">
                <receipt-file-upload v-model="form.receipt_files" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-row gutter="16">
            <a-col :span="24" :md="8" :xl="6" style="width: 256px">
              <a-input
                v-model="materialNumber"
                :placeholder="$t('请扫描产品条码')"
                @keyup.enter.native="scanMaterial"
                allowClear
              />
            </a-col>
            <a-space>
              <a-button type="primary" @click="openMaterialModal">{{ $t("选择产品") }}</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="small"
              :loading="tableLoading"
              :columns="columns"
              :data-source="receiptMaterialItems"
              :pagination="false"
              :scroll="{ x: 1580 }"
            >
              <div slot="total_quantity" slot-scope="value, item, index">
                <a-input-number v-model="item.total_quantity" size="small"></a-input-number>
              </div>
              <div slot="batch_number" slot-scope="value, item, index">
                <a-row gutter="4">
                  <a-col :span="20">
                    <a-input v-model="item.batch_number" :disabled="!item.enable_batch_control" size="small"></a-input>
                  </a-col>
                  <a-col :span="4">
                    <a-button type="primary" icon="plus" size="small" @click="addBatch(item, index)"></a-button>
                  </a-col>
                </a-row>
              </div>
              <div slot="weight" slot-scope="value, item, index">
                <span v-if="item.total_quantity && item.weight">
                  {{ NP.times(item.total_quantity, item.weight, 0.001) }}
                </span>
              </div>
              <div slot="volume" slot-scope="value, item, index">
                <span v-if="item.total_quantity && item.volume">
                  {{ NP.times(item.total_quantity, item.volume) }}
                </span>
              </div>
              <div slot="remark" slot-scope="value, item, index">
                <a-input v-model="item.remark" size="small"></a-input>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <material-select-modal
      v-model="materialModalVisible"
      @select="onSelectMaterial"
      @confirm="onConfirm"
    ></material-select-modal>
  </div>
</template>

<script>
import { orderTypeOption, warehouseOption, materialOption } from "@/api/option";
import { receiptOrderCreate } from "@/api/stockIn";
import { getReceiptNumber } from "@/api/data";
import { columns } from "./columns";
import { rules } from "./rules";
import moment from "moment";
import NP from "number-precision";
import { receiptOrderRetrieve, receiptOrderMaterials } from "@/api/stockIn";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    CounterpartySelect: () => import("@/components/CounterpartySelect/"),
    // AreaSelect: () => import("@/components/AreaSelect/"),
    ReceiptFileUpload: () => import("@/components/ReceiptFileUpload/index"),
    MaterialSelectModal: () => import("@/components/MaterialSelectModal/index"),
  },
  inject: ["reloadPage"],
  data() {
    return {
      NP,
      rules,
      moment,
      columns,
      loading: false,
      tableLoading: false,
      form: {},
      receiptMaterialItems: [],

      orderTypeItems: [],
      clientItems: [],
      warehouseItems: [],
      materialNumber: "",
      materialModalVisible: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    defaultWarehouse() {
      return this.$store.state.user.defaultWarehouse;
    },
    defaultClient() {
      return this.$store.state.user.defaultClient;
    },
  },
  methods: {
    initData() {
      this.resetForm();
      let receiptOrder = this.$route.query.id;
      if (receiptOrder) {
        receiptOrderRetrieve({ id: receiptOrder }).then((data) => {
          this.form = data;

          getReceiptNumber().then((data) => {
            this.form.number = data.number;
          });
        });

        receiptOrderMaterials({ id: receiptOrder }).then((data) => {
          const receiptMaterialItems = [];
          for (let item of data) {
            receiptMaterialItems.push({
              ...item,
              weight: item.material_weight,
              volume: item.material_volume,
              spec: item.material_spec,
            });
          }

          this.receiptMaterialItems = receiptMaterialItems;
        });
      } else {
        getReceiptNumber().then((data) => {
          this.form.number = data.number;
        });
      }

      orderTypeOption({ page_size: 999999, type: "inbound" }).then((data) => {
        this.orderTypeItems = data.results;
      });

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onConfirm(items) {
      for (const item of items) {
        this.onSelectMaterial(item);
      }
    },
    onSelectMaterial(item) {
      this.receiptMaterialItems.splice(0, 0, {
        id: item.id,
        material: item.id,
        material_name: item.name,
        material_number: item.number,
        total_quantity: 1,
        batch_number: item.enable_batch_control ? `B${moment().format("YYYYMMDDHHmm")}` : "N/A",
        unit: item.unit,
        weight: item.weight,
        volume: item.volume,
        spec: item.spec,
        remark: "",
        enable_batch_control: item.enable_batch_control,
      });
    },
    removeMaterial(index) {
      let receiptMaterialItems = [...this.receiptMaterialItems];
      receiptMaterialItems.splice(index, 1);
      this.receiptMaterialItems = [...receiptMaterialItems];
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.receiptMaterialItems.length == 0) {
            this.$message.warn(this.$t("未添加产品"));
            return;
          }

          for (let index in this.receiptMaterialItems) {
            let receiptMaterialItem = this.receiptMaterialItems[index];
            if (receiptMaterialItem.total_quantity === null || receiptMaterialItem.total_quantity < 0) {
              this.$message.warn(`${$t("请检查第")}${parseInt(index) + 1}${$t("行数量")}`);
              return;
            }
            if (receiptMaterialItem.batch_number.length === 0) {
              this.$message.warn(`${$t("请检查第")}${parseInt(index) + 1}${$t("行批次编号")}`);
              return;
            }
          }

          let formData = { ...this.form, receipt_material_items: this.receiptMaterialItems };
          this.loading = true;
          receiptOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.form = {
        number: "",
        warehouse: this.defaultWarehouse,
        client: this.defaultClient,
      };
      this.receiptMaterialItems = [];
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning(this.$t("请扫描产品条码"));
        return false;
      }

      this.tableLoading = true;
      materialOption({ search: this.materialNumber })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning(this.$t("没有找到相关产品, 请确认扫描的产品编号！"));
            return false;
          }
          let item = data.results[0];
          this.receiptMaterialItems.splice(0, 0, {
            id: item.id,
            material: item.id,
            material_name: item.name,
            material_number: item.number,
            total_quantity: 1,
            batch_number: item.enable_batch_control ? `B${moment().format("YYYYMMDDHHmm")}` : "N/A",
            unit: item.unit,
            weight: item.weight,
            volume: item.volume,
            spec: item.spec,
            remark: "",
            enable_batch_control: item.enable_batch_control,
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addBatch(item, index) {
      let new_item = { ...item };
      new_item["batch_number"] = item.enable_batch_control ? `B${moment().format("YYYYMMDDHHmm")}` : "N/A";
      this.receiptMaterialItems.splice(index, 0, new_item);
    },
    openMaterialModal() {
      this.materialModalVisible = true;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
